// Spinner.js
import React from 'react';
import './Spinner.css';

const Spinner = () => {
    return (
        <div className="main-spin">
        <div class="loader"></div>
        </div>

    );
}

export default Spinner;
