import React from 'react';
const HomeFooter = () => {
  return (
    <footer class="footer" id="footer">

		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="flogo">
						<a href="#"><img id="logoImage" src="assets/images/footer-ogo.png" alt=""/></a>
					</div>
					{/* <div class="social-links">
						<ul>
							<li>
								<a href="#">
									<i class="fab fa-facebook-f"></i>
								</a>
							</li>
							<li>
								<a href="#">
									<i class="fab fa-linkedin-in"></i>
								</a>
							</li>
							<li>
								<a href="#">
									<i class="fab fa-instagram"></i>
								</a>
							</li>
						</ul>
					</div> */}
					<div class="footer-menu">
					</div>
				</div>
			</div>
		</div>
		<div class="copy-bg">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
							<p>Copyright © 2024. All Rights Reserved By Gamenow</p>
					</div>
				</div>
			</div>
		</div>
	</footer> 
  );
};

export default HomeFooter;
