import React, {useEffect, useState, useRef} from 'react';
import {getUserByMsisdn} from "../services/client-login-service";
import { Link } from 'react-router-dom';

const Profile = () => {
    const inputRef = useRef(null);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const input = document.getElementById('input-dob');
        const minDate = new Date('2000-01-01').toISOString().split('T')[0];
        input.setAttribute('min', minDate);

        const fetchData = async () => {
            try {
                const msisdn = {
                    'msisdn': localStorage.getItem('no-msisdn'),
                  }
                const response = await getUserByMsisdn(msisdn);
                setUserData(response);
            } catch (error) {
                alert("getUserByMsisdn() error: " + error);
            }
        };

        fetchData();

    }, []);
    const handleFieldClick = () => {
        inputRef.current.focus();
        inputRef.current.value = '2000-01-01';
    };

    

    const [selectedAvatar, setSelectedAvatar] = useState(null);

    const handleAvatarChange = (event) => {
        setSelectedAvatar(event.target.value);
    };



    return (
        <html>

        <div class="modal fade login-modal sign-in" id="signin" tabindex="-1" role="dialog" aria-labelledby="signin"
             aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered " role="document">
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{fontSize:'18px' ,border: '2px solid',borderRadius: '50%',padding: '6px',display: 'flex',justifyContent: 'center',alignItems: 'center'}}><span
                        aria-hidden="true">&times;</span></button>
                    <div class="modal-body">
                        <ul class="nav l-nav" role="tablist">
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-m_login" role="tabpanel"
                                 aria-labelledby="pills-m_login-tab">
                                <div class="header-area">
                                    <h4 class="title">Welcome 
                                    </h4>
                                </div>

                                {/* <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '20px'
                                }}>
                                    <img src="assets/images/gamenow.png" alt=""/>
                                </div> */}


                               <div class="form-area">
                                    <form >
                                        <div class="form-group">
                                            <input type="text"
                                                   class="input-field"
                                                   id="input-name"
                                                   placeholder="Username"
                                                   value={userData && userData.userName}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="date"
                                                className="input-field"
                                                id="input-dob"
                                                placeholder="Enter your Date of Birth"
                                                ref={inputRef}
                                                onClick={handleFieldClick}
                                                value={userData && userData.userDob}
                                            />
                                        </div>



                                        <div class="form-group">
                                            <input type="email"
                                                   class="input-field"
                                                   id="input-email"
                                                   placeholder="Email"
                                                   value={userData && userData.userEmail}
                                            />
                                        </div>
                                        <div class="form-group">
                                            <input type="password" class="input-field" id="input-password"
                                                   placeholder="Password"
                                                   value={userData && userData.userPassword}
                                            />
                                        </div>
                                        <div class="form-group">
                                            <input type="password" class="input-field" id="input-con-password"
                                                   placeholder="Confirm Password"
                                                   value={userData && userData.userConfirmPassword}
                                            />
                                        </div>

                                        <div class="form-group">
                                            <select class="input-field" id="input-gender"
                                                    value={userData && userData.gender}>
                                                <option value="">Select your gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </div>


                                        <div className="form-group"
                                             style={{justifyContent: 'center', display: 'block'}}>
                                            <div style={{justifyContent: 'center', display: 'flex'}}>
                                                <label htmlFor="input-avatar" style={{color: 'white'}}>Avatar:</label>
                                            </div>

                                            <div className="avatar-group"
                                                 style={{justifyContent: 'center', display: 'flex'}}>
                                                <input
                                                    type="radio"
                                                    name="avatar"
                                                    id="avatar1"
                                                    value="avatar1"
                                                    checked='true'
                                                    onChange={handleAvatarChange}
                                                />
                                                <label htmlFor="avatar1">
                                                    <img src="assets/images/avatar-2.png" alt=""/>
                                                </label>

                                                <input
                                                    type="radio"
                                                    name="avatar"
                                                    id="avatar2"
                                                    value="avatar2"
                                                    checked={selectedAvatar === 'avatar2'}
                                                    onChange={handleAvatarChange}
                                                />
                                                <label htmlFor="avatar2">
                                                    <img src="assets/images/avatar-3.png" alt=""/>
                                                </label>

                                                <input
                                                    type="radio"
                                                    name="avatar"
                                                    id="avatar3"
                                                    value="avatar3"
                                                    checked={selectedAvatar === 'avatar3'}
                                                    onChange={handleAvatarChange}
                                                />
                                                <label htmlFor="avatar3">
                                                    <img src="assets/images/avatar-5.png" alt=""/>
                                                </label>

                                                <input
                                                    type="radio"
                                                    name="avatar"
                                                    id="avatar4"
                                                    value="avatar4"
                                                    checked={selectedAvatar === 'avatar4'}
                                                    onChange={handleAvatarChange}
                                                />
                                                <label htmlFor="avatar4">
                                                    <img src="assets/images/avatar-8.png" alt=""/>
                                                </label>

                                                <input
                                                    type="radio"
                                                    name="avatar"
                                                    id="avatar5"
                                                    value="avatar5"
                                                    checked={selectedAvatar === 'avatar5'}
                                                    onChange={handleAvatarChange}
                                                />
                                                <label htmlFor="avatar5">
                                                    <img src="assets/images/avatar-7.png" alt=""/>
                                                </label>
                                            </div>
                                        </div>


                                        <div class="form-group">
                                            <div class="check-group">
                                                <input type="checkbox" class="check-box-field" id="input-terms"
                                                       style={{marginRight: '4px'}}/>
                                                <label for="input-terms">
                                                    I agree with <Link to="/policy">Terms and Conditions</Link> and <Link href="/policy">Privacy
                                                    Policy</Link>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <button type="submit" class="mybtn2">Save</button>
                                        </div>
                                    </form>
                                </div>

									</div>
								</div>
                        </div>
                    </div>
                </div>
            </div>
        </html>

    );
};

export default Profile;
