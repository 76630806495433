import React, { useEffect, useState  } from 'react';
import { useNavigate } from 'react-router-dom';
import { userSession } from '../services/client-login-service';
import Heart from 'react-heart';
import axios from 'axios';

const NewArrivalCasualBody = () => {
  const navigate = useNavigate();

  // Add loading state
const [isLoading, setIsLoading] = useState(true);

  const handleClick = (gamesThumbnail, gamesUrl) => {
      localStorage.setItem('gameThumbnail', gamesThumbnail);
      localStorage.setItem('gameUrl', gamesUrl);
      navigate('../game-component1');
  }

  const games = [
    { thumbnail: 'Professor-Bubble.webp', url: '048.Professor%20Bubble/' },
    { thumbnail: 'Halloween_Bubble_Shooter.webp', url: '024.Halloween%20Bubble%20Shooter/' },
    { thumbnail: 'Traffic-Racer.webp', url: '120.Traffic%20Racer/' },
    { thumbnail: 'Frog-Super-Bubbles.webp', url: '110.Frog%20Super%20Bubbles/' },

  ];

  const [likedGames, setLikedGames] = useState(Array(games.length).fill(false));

  const handleHeartClick = async (index) => {
      const updatedLikedGames = [...likedGames];
      updatedLikedGames[index] = !updatedLikedGames[index];

      setLikedGames(updatedLikedGames);

      try {
          const likedGame = games[index];
          const msisdn = localStorage.getItem('no-msisdn');

          const requestBody = {
              msisdn: msisdn,
              thumbnail: likedGame.thumbnail,
              url: likedGame.url,
          };
          const response = await axios.post('https://score.mmsdp.com/api/add-game', requestBody);
          if (response.data.success) {
          } else {
              console.error('Error liking the game:', response.data.message);
          }
      } catch (error) {
          console.error('Error liking the game:', error);
      }
  };

  const handleHeartUnclick = async (index) => {
      const updatedLikedGames = [...likedGames];
      updatedLikedGames[index] = !updatedLikedGames[index];

      setLikedGames(updatedLikedGames);


      try {
          const likedGame = games[index];
          const msisdn = localStorage.getItem('no-msisdn');
          const requestBody = {
              msisdn: msisdn,
              thumbnail: likedGame.thumbnail,
              url: likedGame.url,
          };
          const response = await axios.get(`https://score.mmsdp.com/api/delete-games/${msisdn}`);
          if (response.data.success) {
          } else {
              console.error('Error unliking the game:', response.data.message);
          }
      } catch (error) {
          console.error('Error unliking the game:', error);
      }
  }


  useEffect(() => {
      const fetchData = async () => {
          try {
              const session = {
                  userSessionId: localStorage.getItem('UserSessionId'),
                  sessionType: 'Games',
                  userId: localStorage.getItem('jwt_j'),
              };

              await userSession(session);
              // Additional data loading logic if needed...

              setIsLoading(false);
          } catch (error) {
              console.error('Error loading data:', error);
              // Handle error loading data...
              setIsLoading(false);
          }
      };

      fetchData();
  }, []);

  return (
      <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
      <div>
          <section className="breadcrumb-area games carousel-background">
              <div className="content">
              </div>
          </section>
         
          <section className="game-play-section game-play-section2" style={{  minHeight: '300px' }}> 
            <h1 class='CETEGORIES' style={{ fontSize: '1.5em' }}>NEW ARRIVALS - CASUAL</h1>               
            <div className=" games-container" style={{ display: 'flex', flexWrap: 'wrap' , justifyContent:'center',gap:'20px'}}>
            {games.map((game, index) => (
                            <div key={index} className="single-play1 action-games" style={{ marginBottom: '20px' }}>
                                <div className="image" style={{ width:'100%', display:'flex', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>
                                    <img src={`assets/Icons/${game.thumbnail}`} alt="action-game-images" className="zoomable-image"  loading="lazy"  /> 
                                </div>
                                <div className=" play-like-action" style={{display: 'flex' ,alignItems: 'end'}}>              
                                    <a  className='like-button' style={{display:'flex', fontSize:'16px'}}
                                        onClick={() => handleClick(`assets/Icons/${game.thumbnail}`, game.url)}
                                    >
                                        Play
                                    </a>
                                    <div style={{
                                        }}>
                                        <Heart 
                                        className='category-games-like'
                                        style={{
                                            stroke: 'white',
                                            transform: 'scale(0.5)',
                                            color: likedGames[index] ? 'red' : 'white',
                                            outline: 'white',
                                            fill:likedGames[index] ? 'red' : 'transparent',
                                            height:'50px',
                                            opacity:'0.7',
                                            marginLeft:'-7px'
                                        }}
                                        isActive={likedGames[index]}
                                        onClick={() => handleHeartClick(index)}
                                        onDoubleClick={() => handleHeartUnclick(index)}
                                        animationScale={1.2}
                                    />
                                        </div>
                                </div>
                            </div>
                    ))}
                </div>
            </section>
      </div>
      </div>
  );
};

export default NewArrivalCasualBody
