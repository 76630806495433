import AuthenticatedGamesHeader from "./AuthenticatedGamesHeader";
import HomeFooter from "./HomeFooter";
import React, { useState } from "react";
import MostCasualBody from "./MostCasualBody";
import Spinner from "../components/Spinner";
import useLoading from "../components/CustomHook";

const MostCasual = () => {
  const loading=useLoading()
  const [showComponents, setShowComponents] = useState(false);

  const handleJoinUsClick = () => {
    setShowComponents(true);
  }
  return (
    <div className={`App ${loading ? 'loading' : ''}`}>
      {loading && <Spinner/>}
    <html lang="en">
      <head>
        
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <meta name="forntEnd-Developer" content="Mamunur Rashid" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title> GAMENOW</title>
        <link rel="shortcut icon" href="assets/images/favicon.png" type="image/x-icon" />
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        <link rel="stylesheet" href="assets/css/plugin.css" />
        <link rel="stylesheet" href="assets/css/style.css" />
        <link rel="stylesheet" href="assets/css/responsive.css" />
        

      </head>
      <body>
        <AuthenticatedGamesHeader onJoinUsClick={handleJoinUsClick} />
        <MostCasualBody />
        <HomeFooter />
      </body>
    </html>
    </div>
  );
};

export default MostCasual
