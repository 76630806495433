import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userSession } from '../services/client-login-service';
import Heart from 'react-heart';
import axios from 'axios';
import Spinner from '../components/Spinner';
// import '../App.css';

const DownloadGamesBody = () => {
    const navigate = useNavigate();

      // Add loading state
  const [isLoading, setIsLoading] = useState(true);

    const handleClick = (gamesThumbnail, gamesUrl) => {
        localStorage.setItem('gameThumbnail', gamesThumbnail);
        localStorage.setItem('gameUrl', gamesUrl);
        navigate('../game-component1');
    }

    const actionGames=[
        { thumbnail:'sniper-3d.png',url:'sniper-3d-mod_4.35.13-an1.com.apk'},
        { thumbnail:'Tapventures.jpg',url:'Tapventures-%28MOD%29_6.7-Android-1.com.apk'},
        { thumbnail:'war-machines.png',url:'war-machines_8.33.1-an1.com.apk'},
        { thumbnail:'zombie-gunship-survival.jpg',url:'zombie-gunship-survival-mod_1.6.98-an1.com.apk'},
        { thumbnail:'dead-target-zombie.jpg',url:'dead-target-mod_4.130.0-an1.com.apk'},
    ]

    const strategyGames=[
        { thumbnail:'alien-creep.png',url:'alien-creep-td-mod_2.32.4-an1.com.apksniper-3d-mod_4.35.13-an1.com.apk'},
        { thumbnail:'bid-wars-2.png',url:'bid-wars-mod_2.57-an1.com.apk'},
        { thumbnail:'bloons-td-6.png',url:'bloons-td-6-mod_42.2-an1.com.apk'},
        { thumbnail:'game-of-warriors.jpg',url:'game-of-warriors-mod_1.6.4-an1.com.apk'},
        { thumbnail:'megapolis.png',url:'megapolis_47713-an1.com.apk'},
    ]

    const racingGames=[
        { thumbnail:'drag-racing11.jpg',url:'drag-racing-mod_4.1.7-an1.com.apk'},
        { thumbnail:'extreme-car-driving.png',url:'extreme-car-driving-mod_6.84.15-an1.com.apk'},
        { thumbnail:'moto-rider-go.jpg',url:'moto-rider-go-mod_1.92.1-an1.com.apk'},
        { thumbnail:'race.jpg',url:'race-mod_1.1.62-an1.com.apk'},
        { thumbnail:'street-racing-3d.jpg',url:'street-racing-3d-mod_7.4.6-an1.com.apk'},
    ]

    const shootingGames=[
        { thumbnail:'bowmasters.png',url:'bowmasters-mod_6.0.7-an1.com.apk'},
        { thumbnail:'Call-of-Duty-Heroes.jpg',url:'Call-of-Duty-Heroes-%28MOD%29-%28No-need-root%29_2.1.0-Android-1.com.apk'},
        { thumbnail:'left-4-dead.jpg',url:'left-4-dead-2_2.0-an1.com.apk'},
        { thumbnail:'Magnum.jpg',url:'Magnum_v1.0596_mod_apkdone.com.apk'},
        { thumbnail:'Supreme-Duelist-Stickman.jpg',url:'Supreme-Duelist-Stickman-3.5.2-Mod-www.ReXdl.com.apk'},
    ]

    // const [likedGames, setLikedGames] = useState(Array(actionGames.length).fill(false));

    // const handleHeartClick = async (index) => {
    //     const updatedLikedGames = [...likedGames];
    //     updatedLikedGames[index] = !updatedLikedGames[index];

    //     setLikedGames(updatedLikedGames);

    //     try {
    //         const likedGame = actionGames[index];
    //         const msisdn = localStorage.getItem('no-msisdn');
    //         const requestBody = {
    //             msisdn: msisdn,
    //             thumbnail: likedGame.thumbnail,
    //             url: likedGame.url,
    //         };
    //         const response = await axios.post('https://score.mmsdp.com/api/add-game', requestBody);
    //         if (response.data.success) {
    //         } else {
    //             console.error('Error liking the game:', response.data.message);
    //         }
    //     } catch (error) {
    //         console.error('Error liking the game:', error);
    //     }
    // };

    // const handleHeartUnclick = async (index) => {
    //     const updatedLikedGames = [...likedGames];
    //     updatedLikedGames[index] = !updatedLikedGames[index];

    //     setLikedGames(updatedLikedGames);


    //     try {
    //         const likedGame = actionGames[index];
    //         const msisdn = localStorage.getItem('no-msisdn');
    //         const requestBody = {
    //             msisdn: msisdn,
    //             thumbnail: likedGame.thumbnail,
    //             url: likedGame.url,
    //         };
    //         const response = await axios.get(`https://score.mmsdp.com/api/delete-games/${msisdn}`);
    //         if (response.data.success) {
    //         } else {
    //             console.error('Error unliking the game:', response.data.message);
    //         }
    //     } catch (error) {
    //         console.error('Error unliking the game:', error);
    //     }
    // }

const [actionLikedGames, setActionLikedGames] = useState(Array(actionGames.length).fill(false));
const [strategyLikedGames, setStrategyLikedGames] = useState(Array(strategyGames.length).fill(false));
const [racingLikedGames, setRacingLikedGames] = useState(Array(racingGames.length).fill(false));
const [shootingLikedGames, setShootingLikedGames] = useState(Array(shootingGames.length).fill(false));

const handleHeartClick = async (index, category) => {
    let updatedLikedGames = [];
    let setLikedGames = null;
    let likedGame = null;

    switch (category) {
        case 'action':
            updatedLikedGames = [...actionLikedGames];
            setLikedGames = setActionLikedGames;
            likedGame = actionGames[index];
            break;
        case 'strategy':
            updatedLikedGames = [...strategyLikedGames];
            setLikedGames = setStrategyLikedGames;
            likedGame = strategyGames[index];
            break;
        case 'racing':
            updatedLikedGames = [...racingLikedGames];
            setLikedGames = setRacingLikedGames;
            likedGame = racingGames[index];
            break;
        case 'shooting':
            updatedLikedGames = [...shootingLikedGames];
            setLikedGames = setShootingLikedGames;
            likedGame = shootingGames[index];
            break;
        default:
            break;
    }

    if (setLikedGames && likedGame) {
        updatedLikedGames[index] = !updatedLikedGames[index];
        setLikedGames(updatedLikedGames);
        try {
            const msisdn = localStorage.getItem('no-msisdn');
            const requestBody = {
                msisdn: msisdn,
                thumbnail: likedGame.thumbnail,
                url: likedGame.url,
            };

            const response = await axios.post('https://score.mmsdp.com/api/add-game', requestBody);
            if (!response.data.success) {
                console.error('Error liking the game:', response.data.message);
            }
        } catch (error) {
            console.error('Error liking the game:', error);
        }
    }
};

const handleHeartUnclick = async (index, category) => {
    let updatedLikedGames = [];
    let setLikedGames = null;
    let likedGame = null;

    switch (category) {
        case 'action':
            updatedLikedGames = [...actionLikedGames];
            setLikedGames = setActionLikedGames;
            likedGame = actionGames[index];
            break;
        case 'strategy':
            updatedLikedGames = [...strategyLikedGames];
            setLikedGames = setStrategyLikedGames;
            likedGame = strategyGames[index];
            break;
        case 'racing':
            updatedLikedGames = [...racingLikedGames];
            setLikedGames = setRacingLikedGames;
            likedGame = racingGames[index];
            break;
        case 'shooting':
            updatedLikedGames = [...shootingLikedGames];
            setLikedGames = setShootingLikedGames;
            likedGame = shootingGames[index];
            break;
        default:
            break;
    }

    if (setLikedGames && likedGame) {
        updatedLikedGames[index] = !updatedLikedGames[index];
        setLikedGames(updatedLikedGames);

        try {
            const msisdn = localStorage.getItem('no-msisdn');
            const requestBody = {
                msisdn: msisdn,
                thumbnail: likedGame.thumbnail,
                url: likedGame.url,
            };

            const response = await axios.get(`https://score.mmsdp.com/api/delete-games/${msisdn}`);
            if (!response.data.success) {
                console.error('Error unliking the game:', response.data.message);
            }
        } catch (error) {
            console.error('Error unliking the game:', error);
        }
    }
};


    useEffect(() => {
        const fetchData = async () => {
            try {
                const session = {
                    userSessionId: localStorage.getItem('UserSessionId'),
                    sessionType: 'Games',
                    userId: localStorage.getItem('jwt_j'),
                };

                await userSession(session);
                // Additional data loading logic if needed...

                setIsLoading(false);
            } catch (error) {
                console.error('Error loading data:', error);
                // Handle error loading data...
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const [loading, setLoading] = useState(true);

	useEffect(() => {
	  // Simulate loading delay with setTimeout
	  const timer = setTimeout(() => {
		setLoading(false); // Set loading to false after a delay
	  }, 1000); // Adjust the delay as needed
  
	  // Clean up the timer when the component unmounts
	  return () => clearTimeout(timer);
	}, []);

    return (
        <div className={`App ${loading ? 'loading' : ''}`}>
            {loading && <Spinner/>}
        <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
        {!loading &&(<div>
            <section className="breadcrumb-area games carousel-background">
                <div className="content">
                    <h4 className='background-heading'>PLAY SMARTER AMPLIFY YOUR GAMING JOURNEY!</h4>
                </div>
            </section>
            <section className="games-filter">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="filter-wrapp" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div className='download-text'>Download Your Favorite Games!</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="game-play-section download-game-play-section" style={{   }}> 
            <h1 class='CETEGORIES' style={{ fontSize: '1.5em',marginBottom:'18px' }}>ACTION</h1>               
            <div className="games-container" style={{ display: 'flex', flexWrap: 'wrap' , justifyContent:'center' ,gap:'16px',marginBottom:'25px'}}>
            {actionGames.map((game, index) => (
                        <div key={index} className="single-play1 download-game-box " style={{ marginBottom: '20px' }}>
                            <div className="image" style={{ width:'100%', display:'flex', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>
                                <img src={`assets/Icons/${game.thumbnail}`} alt="games-images" className="zoomable-image download-game-image"  loading="lazy"  /> 
                                </div>
                            <div className='download-like-div' style={{  display: 'flex', alignItems: 'end' , position:'relative',marginTop:'10px', width:'100%'}}>              
                                    <a className='download-button' style={{ display:'flex', fontSize:'16px',width:'100%',justifyContent:'center'}} href={`https://cdn-content.mmsdp.com/newgames-may-2/Downloadable%20Games/Action/${game.url}`}
                                        // onClick={() => handleClick(`assets/Icons/${game.thumbnail}`, game.url)}
                                    >
                                        Download
                                    </a>    
                                    {/* <div style={{
                                        }}>
                                        <Heart 
                                        className=' heart-like-download'
                                            style={{
                                                stroke: 'white',
                                                transform: 'scale(0.5)',
                                                color: actionLikedGames[index] ? 'red' : 'white',
                                                outline: 'white',
                                                fill:actionLikedGames[index] ? 'red' : 'transparent',
                                                height:'50px',
                                                opacity:'0.7',
                                                marginLeft:'-7px'
                                                
                                            }}
                                            isActive={actionLikedGames[index]}
                                            onClick={() => handleHeartClick(index , 'action')}
                                            onDoubleClick={() => handleHeartUnclick(index, 'action')}
                                            animationScale={1.2}
                                        />
                                    </div> */}
                            </div>
                        </div>
                    ))}
                </div>
            <h1 class='CETEGORIES' style={{ fontSize: '1.5em' ,marginTop: '10px',marginBottom:'18px'}}>STRATEGY</h1>               
            <div className="games-container" style={{ display: 'flex', flexWrap: 'wrap' , justifyContent:'center' ,gap:'20px',marginBottom:'25px'}}>
            {strategyGames.map((game, index) => (
                        <div key={index} className="single-play1 download-game-box" style={{ marginBottom: '20px' }}>
                            <div className="image" style={{ width:'100%', display:'flex', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>
                                <img src={`assets/Icons/${game.thumbnail}`} alt="games-images" className="zoomable-image download-game-image"  loading="lazy"  /> 
                                </div>
                            <div className='download-like-div' style={{  display: 'flex', alignItems: 'end',marginTop:'10px' }}>              
                                    <a className='download-button' style={{ display:'flex', fontSize:'16px',justifyContent:'center',width:'100%'}} href={`https://cdn-content.mmsdp.com/newgames-may-2/Downloadable%20Games/Strategy/${game.url}`}
                                        // onClick={() => handleClick(`assets/Icons/${game.thumbnail}`, game.url)}
                                    >
                                        Download
                                    </a>    
                                    {/* <div style={{
                                        }}>
                                        <Heart 
                                        className=' heart-like-download'
                                            style={{
                                                stroke: 'white',
                                                transform: 'scale(0.5)',
                                                color: strategyLikedGames[index] ? 'red' : 'white',
                                                outline: 'white',
                                                fill:strategyLikedGames[index] ? 'red' : 'transparent',
                                                height:'50px',
                                                opacity:'0.7',
                                                marginLeft:'-7px'
                                                
                                            }}
                                            isActive={strategyLikedGames[index]}
                                            onClick={() => handleHeartClick(index , 'strategy')}
                                            onDoubleClick={() => handleHeartUnclick(index , 'strategy')}
                                            animationScale={1.2}
                                        />
                                    </div> */}
                            </div>
                        </div>
                    ))}
                </div>
            <h1 class='CETEGORIES' style={{ fontSize: '1.5em' ,marginTop: '10px',marginBottom:'18px'}}>RACING</h1>               
            <div className="games-container" style={{ display: 'flex', flexWrap: 'wrap' , justifyContent:'center' ,gap:'20px',marginBottom:'25px'}}>
            {racingGames.map((game, index) => (
                        <div key={index} className="single-play1 download-game-box" style={{ marginBottom: '20px' }}>
                            <div className="image" style={{ width:'100%', display:'flex', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>
                                <img src={`assets/Icons/${game.thumbnail}`} alt="games-images" className="zoomable-image download-game-image"  loading="lazy"  /> 
                                </div>
                            <div className='download-like-div' style={{  display: 'flex', alignItems: 'end' , position:'relative',marginTop:'10px'}}>              
                                    <a className='download-button' style={{ display:'flex', fontSize:'16px',justifyContent:'center',width:'100%'}} href={`https://cdn-content.mmsdp.com/newgames-may-2/Downloadable%20Games/Racing/${game.url}`}
                                        // onClick={() => handleClick(`assets/Icons/${game.thumbnail}`, game.url)}
                                    >
                                        Download
                                    </a>    
                                    {/* <div style={{
                                        }}>
                                        <Heart 
                                        className=' heart-like-download'
                                            style={{
                                                stroke: 'white',
                                                transform: 'scale(0.5)',
                                                color: racingLikedGames[index] ? 'red' : 'white',
                                                outline: 'white',
                                                fill:racingLikedGames[index] ? 'red' : 'transparent',
                                                height:'50px',
                                                opacity:'0.7',
                                                marginLeft:'-7px'
                                            }}
                                            isActive={racingLikedGames[index]}
                                            onClick={() => handleHeartClick(index, 'racing')}
                                            onDoubleClick={() => handleHeartUnclick(index, 'racing')}
                                            animationScale={1.2}
                                        />
                                    </div> */}
                            </div>
                        </div>
                    ))}
                </div>
            <h1 class='CETEGORIES' style={{ fontSize: '1.5em' ,marginTop: '10px',marginBottom:'18px'}}>SHOOTING</h1>               
            <div className="games-container" style={{ display: 'flex', flexWrap: 'wrap' , justifyContent:'center' ,gap:'20px',marginBottom:'25px'}}>
            {shootingGames.map((game, index) => (
                        <div key={index} className="single-play1 download-game-box" style={{ marginBottom: '20px' }}>
                            <div className="image" style={{ width:'100%', display:'flex', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>
                                <img src={`assets/Icons/${game.thumbnail}`} alt="games-images" className="zoomable-image download-game-image"  loading="lazy"  /> 
                                </div>
                            <div className='download-like-div' style={{  display: 'flex', alignItems: 'end' ,position:'relative',marginTop:'10px'}}>              
                                    <a className='download-button' style={{ display:'flex', fontSize:'16px',justifyContent:'center',width:'100%'}} href={`https://cdn-content.mmsdp.com/newgames-may-2/Downloadable%20Games/Shooter/${game.url}`}>
                                        Download
                                    </a>    
                                    {/* <div style={{
                                        }}>
                                        <Heart 
                                        className=' heart-like-download'
                                            style={{
                                                stroke: 'white',
                                                transform: 'scale(0.5)',
                                                color: shootingLikedGames[index] ? 'red' : 'white',
                                                outline: 'white',
                                                fill:shootingLikedGames[index] ? 'red' : 'transparent',
                                                height:'50px',
                                                opacity:'0.7',
                                                marginLeft:'-7px'
                                            }}
                                            isActive={shootingLikedGames[index]}
                                            onClick={() => handleHeartClick(index, 'shooting')}
                                            onDoubleClick={() => handleHeartUnclick(index ,'shooting')}
                                            animationScale={1.2}
                                        />
                                    </div> */}
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>)}
        </div>
        </div>
        
    );
};

export default DownloadGamesBody;