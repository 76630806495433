import React from 'react';
import { fetchHe } from '../services/fetch_he';
import { Link } from 'react-router-dom';

const AuthenticatedGamesHeader = ({ onJoinUsClick }) => {
	function handleLogout() {
		// Clear localStorage
		localStorage.clear();

		// Reload the React app
		window.location.reload();
	}

	const handleClick = () => {
		fetchHe().then((response) => {
			const msisdn = response.msisdn;
			const modifiedMsisdn = "0" + msisdn.substring(2);
			let element = document.getElementById('input-name');
			element.value = modifiedMsisdn;
			let otpElement = document.getElementById('otp-field');
			otpElement.value = modifiedMsisdn;
		}).catch((error) => {
		});
		onJoinUsClick();
	};


	return (
		<header class="header">
			<div class="overlay"></div>
			<section class="top-header">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">
							<div class="content">
								<div class="right-content">
									{/* <ul class="right-list">
										<li>
											<div class="notofication" data-toggle="modal" data-target="#usernotification">
												<i class="far fa-bell"></i>
											</div>
										</li>
									</ul> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div class="mainmenu-area">
				<div class="container text-center">
					<div class="row justify-content-center">
						<div class="col-lg-12">
							<nav class="navbar navbar-expand-lg navbar-light">
								<Link class="navbar-brand" to="/home">
									<img src="assets/images/logo.png" alt="" />
								</Link>
								<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_menu" aria-controls="main_menu"
									aria-expanded="false" aria-label="Toggle navigation">
									<span class="navbar-toggler-icon"></span>
								</button>
								<div class="collapse navbar-collapse fixed-height" id="main_menu">
								<ul class="navbar-nav mr-auto justify-content-center text-center">
												<li class="nav-item">
													<Link class="nav-link" to="/home">Home</Link>
												</li>
											<li class="nav-item text-center">
												<Link class="nav-link" to="/games-all">All Games</Link>
											</li>
												<li class="nav-item">
													<Link class="nav-link" to="/favorites">
														Favorite Games ❤️
													</Link>
												</li>
												<li class="nav-item">
										<Link class="nav-link" to="/downloadnow">Download Now</Link>
									</li>
												<li class="nav-item">
													<Link class="nav-link" to="/unsubscribe">Unsubsrcibe</Link>
												</li>

											
											<li class="nav-item">
												<Link className="nav-link" onClick={handleLogout}>
													Logout
												</Link>
											</li>
											<li class="nav-item">
													<a href="#" class="myavatar" data-toggle="modal" data-target="#signin" onClick={handleClick}>
										            <img src="assets/images/avatar-1.webp" alt="Join NOW" />
									                </a> 
											</li>
										</ul>
								</div>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}

export default AuthenticatedGamesHeader;
