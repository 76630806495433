import "bootstrap/dist/css/bootstrap.min.css";
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";
import './App.css';
import React, { lazy, Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import MostAction from "./pages/MostAction";
import MostAdventure from "./pages/MostAdventure";
import MostSports from "./pages/MostSports";
import MostRacing from "./pages/MostRacing";
import MostStrategy from "./pages/MostStrategy";
import MostPuzzle from "./pages/MostPuzzle";
import MostCasual from "./pages/MostCasual";
import MostMemory from "./pages/MostMemory";
import MostTwoPlayer from "./pages/MostTwoPlayer";
import NewArrivalAction from "./pages/NewArrivalAction";
import NewArrivalSports from "./pages/NewArrivalSports";
import NewArrivalAdventure from "./pages/NewArrivalAdventure";
import NewArrivalRacing from "./pages/NewArrivalRacing";
import NewArrivalStrategy from "./pages/NewArrivalStrategy";
import NewArrivalPuzzle from "./pages/NewArrivalPuzzle";
import NewArrivalCasual from "./pages/NewArrivalCasual";
import NewArrivalMemory from "./pages/NewArrivalMemory";
import NewArrivalTwoPlayer from "./pages/NewArrivalTwoPlayer";
import Policy from "./pages/Policy";
import DownloadGames from "./pages/DownloadGames";
import Spinner from "./components/Spinner";

const Home = lazy(() => import('./pages/Home'));
const AuthenticatedHome = lazy(() => import('./pages/AuthenticatedHome'));
const AuthenticatedGames = lazy(() => import('./pages/AuthenticatedGames'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const OTPPage = lazy(() => import('./pages/OTPPage'));
const ComponentToDisplay = lazy(() => import('./components/ComponentToDisplay'));
const UnsubscribePage = lazy(() => import('./pages/UnsubscribePage'));

const AuthenticatedGamesAll = lazy(() => import('./pages/AuthenticatedGamesAll'));
const ComponentToDisplayAllGames = lazy(() => import('./components/ComponentToDisplayAllGames'));
const Action = lazy(() => import('./pages/Action'));
const Adventure = lazy(() => import('./pages/Adventure'));
const Racing = lazy(() => import('./pages/Racing'));
const Sports = lazy(() => import('./pages/Sports'));
const Strategy = lazy(() => import('./pages/Strategy'));
const Favorites = lazy(() => import('./pages/Favorites'));
const Download = lazy(() => import('./pages/DownloadGames'));
const Puzzle = lazy(() => import('./pages/Puzzle'));
const Casual = lazy(() => import('./pages/Casual'));
const Memory = lazy(() => import('./pages/Memory'));
const TwoPlayer = lazy(() => import('./pages/TwoPlayer'));
const MostPopular = lazy(() => import('./pages/MostPopular'));
const NewArrivals = lazy(() => import('./pages/NewArrivals'));

ReactGA.initialize("G-48E603Q673");

function App() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const uniqRef = urlParams.get('uniqref');

    

    ReactGA._gaCommandSendPageview(window.location.pathname)
    return (
        <BrowserRouter>
            <Helmet>

            </Helmet>
            <ToastContainer />
            <Suspense fallback={<Spinner/>}>
            <Routes>
            <Route path="/" element={<Home />} />
                    <Route
                        path="/home"
                        element={
                            localStorage.getItem('jwt_j') == null ? (
                                <Navigate to="/" />
                            ) : (
                                <AuthenticatedHome />
                            )
                        }
                    />
                 <Route
                        path="/game-component"
                        element={
                            localStorage.getItem('jwt_j') == null ? (
                                <Navigate to="/index.php" />
                            ) : (
                                <ComponentToDisplay />
                            )
                        }
                    />
                <Route
                        path="/game-component1"
                        element={
                            localStorage.getItem('jwt_j') == null ? (
                                <Navigate to="/index.php" />
                            ) : (
                                <ComponentToDisplayAllGames />
                            )
                        }
                    />
                 <Route
                        path="/games"
                        element={
                            localStorage.getItem('jwt_j') == null ? (
                                <Navigate to="/index.php" />
                            ) : (
                                <AuthenticatedGames />
                            )
                        }
                    />
                    <Route path="/games-all" element={localStorage.getItem('jwt_j') == null ? (<Navigate to="/index.php" />) : (<AuthenticatedGamesAll />)} />
                    <Route path="/strategy" element={localStorage.getItem('jwt_j') == null ? (<Navigate to="/index.php" />) : (<Strategy />)} />

                    <Route path="/sports" element={localStorage.getItem('jwt_j') == null ? (<Navigate to="/index.php" />) : (<Sports />)} />
                    <Route path="/racing" element={localStorage.getItem('jwt_j') == null ? (<Navigate to="/index.php" />) : (<Racing />)} />
                    <Route path="/adventure" element={localStorage.getItem('jwt_j') == null ? (<Navigate to="/index.php" />) : (<Adventure />)} />
                    <Route path="/action" element={localStorage.getItem('jwt_j') == null ? (<Navigate to="/index.php" />) : (<Action />)} />
                    <Route path="/puzzle" element={localStorage.getItem('jwt_j') == null ? (<Navigate to="/index.php" />) : (<Puzzle />)} />
                    <Route path="/casual" element={localStorage.getItem('jwt_j') == null ? (<Navigate to="/index.php" />) : (<Casual />)} />
                    <Route path="/memory" element={localStorage.getItem('jwt_j') == null ? (<Navigate to="/index.php" />) : (<Memory />)} />
                    <Route path="/twoplayer" element={localStorage.getItem('jwt_j') == null ? (<Navigate to="/index.php" />) : (<TwoPlayer />)} />
                    <Route path="/favorites" element={localStorage.getItem('jwt_j') == null ? (<Navigate to="/index.php" />) : (<Favorites />)} />
                    <Route path="/downloadnow" element={localStorage.getItem('jwt_j') == null ? (<Navigate to="/index.php" />) : (<DownloadGames />)} />
                    <Route path="/mostpopular" element={localStorage.getItem('jwt_j') == null ? (<Navigate to="/index.php" />) : (<MostPopular />)} />
                    <Route path="/newarrivals" element={localStorage.getItem('jwt_j') == null ? (<Navigate to="/index.php" />) : (<NewArrivals />)} />
                    <Route path="/mostaction" element={localStorage.getItem('jwt_j') == null ?  (<Navigate to="/index.php" />) : (<MostAction />)} />
                    <Route path="/mostsports" element={localStorage.getItem('jwt_j') == null ?  (<Navigate to="/index.php" />) : (<MostSports />)} />
                    <Route path="/mostadventure" element={localStorage.getItem('jwt_j') == null ? (<Navigate to="/index.php" />) : (<MostAdventure />)} />
                    <Route path="/mostracing" element={localStorage.getItem('jwt_j') == null ?   (<Navigate to="/index.php" />) : (<MostRacing />)} />
                    <Route path="/moststrategy" element={localStorage.getItem('jwt_j') == null ?  (<Navigate to="/index.php" />) : (<MostStrategy />)} />
                    <Route path="/mostpuzzle" element={localStorage.getItem('jwt_j') == null ?   (<Navigate to="/index.php" />) : (<MostPuzzle />)} />
                    <Route path="/mostcasual" element={localStorage.getItem('jwt_j') == null ?   (<Navigate to="/index.php" />) : (<MostCasual />)} />
                    <Route path="/mostmemory" element={localStorage.getItem('jwt_j') == null ?   (<Navigate to="/index.php" />) : (<MostMemory />)} />
                    <Route path="/mosttwoplayer" element={localStorage.getItem('jwt_j') == null ?  (<Navigate to="/index.php" />) : (<MostTwoPlayer />)} />
                    <Route path="/newarrivalaction" element={localStorage.getItem('jwt_j') == null ?  (<Navigate to="/index.php" />) : (<NewArrivalAction />)} />
                    <Route path="/newarrivalsport" element={localStorage.getItem('jwt_j') == null ?   (<Navigate to="/index.php" />) : (<NewArrivalSports />)} />
                    <Route path="/newarrivaladventure" element={localStorage.getItem('jwt_j') == null ? (<Navigate to="/index.php" />): (<NewArrivalAdventure />)} />
                    <Route path="/newarrivalracing" element={localStorage.getItem('jwt_j') == null ?   (<Navigate to="/index.php" />) : (<NewArrivalRacing />)} />
                    <Route path="/newarrivalstrategy" element={localStorage.getItem('jwt_j') == null ?  (<Navigate to="/index.php" />) : (<NewArrivalStrategy />)} />
                    <Route path="/newarrivalpuzzle" element={localStorage.getItem('jwt_j') == null ?   (<Navigate to="/index.php" />) : (<NewArrivalPuzzle />)} />
                    <Route path="/newarrivalcasual" element={localStorage.getItem('jwt_j') == null ?   (<Navigate to="/index.php" />) : (<NewArrivalCasual />)} />
                    <Route path="/newarrivalmemory" element={localStorage.getItem('jwt_j') == null ?   (<Navigate to="/index.php" />) : (<NewArrivalMemory />)} />
                    <Route path="/newarrivaltwoplayer" element={localStorage.getItem('jwt_j') == null ?  (<Navigate to="/index.php" />) : (<NewArrivalTwoPlayer />)} />



                <Route path="/policy" element={<Policy />} />
                <Route path="/index.php" element={<LoginPage />} />
                <Route path="/otphandler" element={<OTPPage />} />
                <Route path="/otphandler-he" element={<OTPPage />} />
                <Route path="/unsubscribe" element={<UnsubscribePage />} />
                <Route
                    path="/noetic_jazz_ads.php"
                    element={<Navigate to={`/index.php?vinfo=${uniqRef}:noetic_jazz_ads`} />} />
                <Route
                    path="/2m1i.php"
                    element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m1i`} />} />
                <Route
                    path="/tiktok.php"
                    element={<Navigate to={`/index.php?vinfo=${uniqRef}:tiktok`} />} />

                <Route
                    path="/2m3i.php"
                    element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m3i`} />} />
                <Route
                    path="/2m4i.php"
                    element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m4i`} />} />
                <Route
                    path="/2m5i.php"
                    element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m5i`} />} />
                <Route
                    path="/2m6i.php"
                    element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m6i`} />} />

                <Route
                    path="/2m15i.php"
                    element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m15i`} />} />

                <Route
                    path="/2m7i.php"
                    element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m7i`} />} />
                <Route
                    path="/4m20i.php"
                    element={<Navigate to={`/index.php?vinfo=${uniqRef}:4m20i`} />} />
                <Route
                    path="/2m9i.php"
                    element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m9i`} />} />
                    <Route
                    path="/4m51i.php"
                    element={<Navigate to={`/index.php?vinfo=${uniqRef}:4m51i`} />} />
                <Route
                    path="/2m13i.php"
                    element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m13i`} />} />

<Route
                    path="/2m18i.php"
                    element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m18i`} />} />

                <Route
                    path="/4m32i.php"
                    element={<Navigate to={`/index.php?vinfo=${uniqRef}:4m32i`} />} />
                <Route
                    path="/noetic_google_ads.php"
                    element={<Navigate to={`/index.php?vinfo=${uniqRef}:noetic_google_ads`} />} />
                <Route
                    path="/mobipium.php"
                    element={<Navigate to={`/index.php?vinfo=${uniqRef}:mobipium`} />} />
                <Route
                    path=""
                    element={<Navigate to={`/index.php`} />} />

            </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
