import HomeFooter from "./HomeFooter";
import React from "react";
import PolicyBody from "./PolicyBody";
const Policy = () => {
 
  return (
    <html lang="en">
      <head>
        
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <meta name="forntEnd-Developer" content="Mamunur Rashid" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>GAMENOW</title>
        <link rel="shortcut icon" href="assets/images/favicon.png" type="image/x-icon" />
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        <link rel="stylesheet" href="assets/css/plugin.css" />
        <link rel="stylesheet" href="assets/css/style.css" />
        <link rel="stylesheet" href="assets/css/responsive.css" />
        

      </head>
      <body>
        <PolicyBody />
        <HomeFooter />
      </body>
    </html>
  );
};

export default Policy;
